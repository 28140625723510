<template>
  <div>
    <div>
      <a-table :columns="columns" :data-source="keyedWishists" bordered>
        <template #type="{ text }">
          <a-tag :color="text === 'free' ? 'green' : text === 'premium' ? 'purple' : 'red'">{{
            text === 'free' ? 'Gratis' : text === 'premium' ? 'Berbayar' : '-'
          }}</a-tag>
        </template>
        <template #price="{ text }">
          {{ priceFormat(text) }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import priceFormat from '@/helpers/rupiahPrice';
import { computed } from '@vue/runtime-core';

const columns = [
  {
    title: 'Course',
    dataIndex: 'name',
    key: 'name',
    slots: { customRender: 'name' },
  },
  {
    title: 'Tipe',
    dataIndex: 'type',
    key: 'type',
    slots: { customRender: 'type' },
    align: 'center',
  },
  {
    title: 'Harga',
    dataIndex: 'price',
    key: 'price',
    slots: { customRender: 'price' },
    align: 'right',
  },
];

export default {
  components: {
  },
  props: {
    wishlists: {
      type: Array,
      default: () => { return [] },
    },
  },
  setup(props) {
    const keyedWishists = computed(() => {
      return props.wishlists.map(wishlist => {
        return {
          ...wishlist,
          key: wishlist._id,
        }
      }) || []
    })
    return {
      columns,
      keyedWishists,
      priceFormat,
    }
  },
}
</script>

<style>
</style>